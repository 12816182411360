import { Route, Routes } from "react-router-dom";

import Distribution from "./Distribution";
import HomeMain from "./Home";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeMain />} />
      <Route path="/distributor" element={<Distribution />} />
    </Routes>
  );
};

export default MainRoutes;
