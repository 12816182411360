import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import img1 from "../components/Images/distributorBanner/1.png."
import img1 from "../components/Images/distributorBanner/1.png"
import img2 from "../components/Images/distributorBanner/2.png"
import img3 from "../components/Images/distributorBanner/3.png"
import img4 from "../components/Images/distributorBanner/4.png"
import img5 from "../components/Images/distributorBanner/5.png"

const DistributorHeroSection = () => {
 const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  fade: true,
  arrows: false,
 };

 const images = [img1, img2, img3,img4,img5]; // Replace with your actual image paths

 return (
  <Box
   sx={{
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    marginTop: 0,
    paddingTop: 0,
   }}
  >
   <Slider {...settings}>
    {images.map((image, index) => (
     <Box
      key={index}
      sx={{
       height:"90vh",
       backgroundImage: `url(${image})`,
       backgroundSize: "cover",
       backgroundPosition: "center",
       position: "relative",
       display: "flex",
       alignItems: "center",
      }}
     >
      {/* Overlay for darkening the background image */}
      {/* <Box
       sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
       }}
      /> */}
      {/* Text content */}
      {/* <Box
       sx={{
        position: "relative",
        zIndex: 2,
        textAlign: "left",
        color: "white",
        maxWidth: "600px", // Limit the width of the text content
        padding: "20px",
        
       }}
       ml={{
        sm:0,
        md:5
       }}
      >
       <Typography
        sx={{
         fontWeight: "bold",
         textTransform: "uppercase",
         letterSpacing: "1.5px",
         mb: 2,
         fontSize:{
            sm:"5rem",
            md:"1.4rem"
         }
        }}

       >
        Your Best Local Taxi Service
       </Typography>
       <Typography

        sx={{
         fontWeight: "bold",
         textTransform: "uppercase",
         mb: 3,
         lineHeight: "1.2",
         fontSize:{
            sm:"1.4rem",
            md:"3rem"
         }
        }}
       >
        Reliable Taxi Services Across India
       </Typography>
       <Typography
  
  
        sx={{
         mb: 4,
         lineHeight: "1.5",
         fontSize:{
            sm:"1rem",
            md:"1.2rem"
         }
        }}
       >
        Experience the ultimate convenience with Taxi-Rider's wide range of
        premium taxi services. Whether you need a ride across town or across the
        country, we've got you covered.
       </Typography>
       <Box sx={{ display: "flex", gap: 2 }}>
        <Button
         variant="contained"
         sx={{
          backgroundColor: "#8e2de2",
          color: "#fff",
          fontWeight: "bold",
          padding: "12px 24px",
          fontSize:{
            sm:"1rem",
            md:"1rem"
         },
          "&:hover": {
           backgroundColor: "#ffffff",
           color:"#8e2de2"
          },
         }}
        >
         Get a Free Estimate
        </Button>
        <Button
         variant="outlined"
         sx={{
          borderColor: "#fff",
          color: "#fff",
          fontWeight: "bold",
          padding: "12px 24px",
          fontSize:{
            sm:"1rem",
            md:"1rem"
         },
          "&:hover": {
           borderColor: "#8e2de2",
           color: "#8e2de2",
          },
         }}
        >
         View Our Services
        </Button>
       </Box>
      </Box> */}
     </Box>
    ))}
   </Slider>
  </Box>
 );
};

export default DistributorHeroSection;
 