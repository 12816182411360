import React, { useRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "../components/Home";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
// import img1 from "..components/Images/Logo.png";
import img1 from "../components/Images/Logo.png";
// import Footer from "../src/components/Footer";
import Footer from "../components/Footer";


const Header = ({ activeSection, scrollToSection }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const menuItems = [
    { text: "Home", section: "home" },
    { text: "Services", section: "services" },
    { text: "About Us", section: "about" },
    { text: "Contact Us", section: "contact" },
    { text: "Distributor", section: "distributor" },
    { text: "Distributor Login", section: "Distributor Login" }, // Register item
  ];

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#fff", boxShadow: "none", margin: 0 }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: 0,
            margin: "auto",
            width: "93%",
          }}
        >
          {/* Left side: Logo */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start", // Aligns the logo to the left
              alignItems: "center",
              fontFamily: "Poppins",
              fontWeight: "400",
            }}
          >
            <Box
              component="img"
              src={img1} // Replace with your logo source path
              alt="Logo"
              sx={{ height: 50 }}
            />
          </Box>

          {/* Right side: Navigation Menu */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" }, // Hide on small screens
              justifyContent: "flex-end", // Aligns the buttons to the right
              alignItems: "center",
            }}
          >
            {menuItems.map((item) => (
              <Button
                key={item.section}
                color="inherit"
                onClick={() => scrollToSection(item.section)}
                sx={{
                  fontSize: "1rem",
                  color:
                    item.section === "Distributor Login"
                      ? "#FFFFFF"
                      : "#000000", // Text color for "Distributor Login" button and others
                  marginLeft: 2,
                  backgroundColor:
                    item.section === "Distributor Login"
                      ? "#4A00E0"
                      : "transparent", // Background color for "Distributor Login" button
                  border:
                    item.section === "Distributor Login"
                      ? "2px solid #4A00E0"
                      : "2px solid transparent", // Border color for "Distributor Login" button
                  "&:hover": {
                    backgroundColor:
                      item.section === "Distributor Login"
                        ? "white"
                        : "transparent", // Background color on hover
                    color:
                      item.section === "Distributor Login"
                        ? "#4A00E0"
                        : "#000000", // Text color on hover
                    border: "2px solid #4A00E0", // Border color on hover
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Hamburger Menu Icon */}
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon color="black" fontSize="2.6rem" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: 240, // Increase this value to make the drawer wider
          },
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.section}
              onClick={() => {
                handleDrawerToggle(); // Close the drawer
                scrollToSection(item.section);
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

const HomeMain = () => {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const LoginRef = useRef(null);

  const [activeSection, setActiveSection] = useState("home");

  const scrollToSection = (section) => {
    if (section === "distributor") {
      window.location.href = "/distributor";
      return;
    } else if (section === "Distributor Login") {
      window.open("https://distributers.janatarides.com/", "_blank");
      return;
    }
    const sectionRefs = {
      home: homeRef,
      services: servicesRef,
      about: aboutRef,
      contact: contactRef,
      LoginRef: LoginRef,
    };

    const headerOffset = 80; // Adjust this value based on your header's height
    const elementPosition =
      sectionRefs[section]?.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    setActiveSection(section);
  };

  useEffect(() => {
    const sectionRefs = {
      home: homeRef,
      services: servicesRef,
      about: aboutRef,
      contact: contactRef,
      LoginRef: LoginRef,
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const section = entry.target.getAttribute("data-section");
            setActiveSection(section);
          }
        });
      },
      { threshold: 0.6 }
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  return (
    <>
      <Header activeSection={activeSection} scrollToSection={scrollToSection} />
      <div
        ref={homeRef}
        data-section="home"
        style={{ padding: "0", minHeight: "100vh" }}
      >
        <Home />
      </div>
      <div
        ref={servicesRef}
        data-section="services"
        style={{ padding: "0", minHeight: "100vh" }}
      >
        <Services />
      </div>
      <div
        ref={aboutRef}
        data-section="about"
        style={{ padding: "0", minHeight: "100vh" }}
      >
        <AboutUs />
      </div>
      <div ref={contactRef} data-section="contact" style={{ padding: "0" }}>
        <ContactUs />
        {/* <Newsletter /> */}
        <Footer />
      </div>
    </>
  );
};

export default HomeMain;
