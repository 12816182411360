import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import img4 from "../components/Images/Astha.png";
import img2 from "../components/Images/OutStationRides.png";
import img3 from "../components/Images/CorporateRides.png";
import img1 from "../components/Images/IntercityRides.png";
import img5 from "../components/Images/SelfRides.png";
import img6 from "../components/Images/SharingRides.png"; // Note the different import name
import img7 from "../components/Images/Group 1171275688.png";
import img8 from "../components/Images/Group 1171275690.png";
import { useTheme } from "@mui/material";

const services = [
  {
    title: "Intercity Service",
    description:
      "InterCity Rides provides a comfortable and safe transportation service for long-distance travel between cities. Featuring a range of vehicles, including motorbikes, auto-rickshaws, and luxury cars, it caters to diverse travel needs. The image showcases the India Gate with festive fireworks in the background, symbolizing regional connectivity. With an inviting purple banner and a soft pink section detailing the service, Intercity Rides ensures a reliable and pleasant journey across regions. Ideal for various commuters, it promises convenience and safety. The service also includes a call to action for more information, encouraging users to learn more.",
    label: "Long Distance",
    bgColor: "#f3e5f5",
    labelColor: "#ba68c8",
    image: img1,
  },
  {
    title: "Out of station Rides",
    description:
      "Outstation Rides offers a reliable and convenient service for travelers seeking to journey beyond city limits. Whether it's a weekend getaway or a business trip, the service provides a range of comfortable vehicles, including cars, SUVs, and vans, suited for long-distance travel. With experienced drivers ensuring safety and smooth rides, travelers can relax and enjoy their journey. Outstation Rides is ideal for family trips, group excursions, or solo adventures, offering flexible options tailored to individual needs. The service guarantees timely arrivals and stress-free travel, making it the go-to choice for outstation travel across regions.",
    label: "Reliable",
    bgColor: "#fff3e0",
    labelColor: "#ffb74d",
    image: img2,
  },
  {
    title: "Corporate Rides",
    description:
      "Corporate Rides offers professional and reliable transportation services tailored for business needs. Designed to provide comfort and punctuality, the service features a fleet of premium vehicles, including sedans and executive cars, perfect for corporate travel. Whether it's airport transfers, business meetings, or corporate events, Corporate Rides ensures a smooth and stress-free experience with skilled chauffeurs and well-maintained vehicles. Prioritizing efficiency, the service accommodates tight schedules and provides a comfortable environment for work on the go. With a focus on professionalism and convenience, Corporate Rides is ideal for executives, teams, and business clients seeking reliable transportation solutions.",
    label: "Scenic",
    bgColor: "#e3f2fd",
    labelColor: "#42a5f5",
    image: img3,
  },
  {
    title: "Astha Rides",
    description:
      "Astha Rides offers an affordable and convenient transportation service designed for everyday commuters and budget-conscious travelers. With a variety of vehicles, including auto-rickshaws, motorbikes, and economical cars, Astha Rides ensures accessible travel within the city. Ideal for short-distance trips, daily commutes, and quick errands, the service provides a cost-effective and reliable option for those looking to navigate urban areas with ease. Focused on simplicity and efficiency, Janta Rides prioritizes timely service and safety, making it a go-to choice for local travelers seeking a practical and pocket-friendly ride. Perfect for the masses, Janta Rides connects people with ease and affordability.",
    label: "Premium",
    bgColor: "#fde8e8",
    labelColor: "#e57373",
    image: img4,
  },
  {
    title: "Self Rides",
    description:
      "Self Rides offers a flexible and convenient solution for travelers who prefer driving themselves. With a wide range of vehicles, including hatchbacks, sedans, SUVs, and bikes, the service allows customers to rent a vehicle of their choice for personal use. Ideal for both short trips and long journeys, Self Rides provides the freedom to travel at your own pace, without the need for a driver. With easy booking options, well-maintained vehicles, and affordable rates, it’s perfect for individuals, families, or groups seeking independence and privacy during their travel. Self Rides ensures a hassle-free experience for those who love to drive.",
    label: "Flexible",
    bgColor: "#e8f0fe",
    labelColor: "#64b5f6",
    image: img5,
  },
  {
    title: "Sharing Rides",
    description:
      "Sharing Rides offers an eco-friendly and cost-effective solution for commuters by allowing multiple passengers to share a vehicle. Ideal for daily travel, carpooling, or ride-sharing, this service helps reduce travel expenses while minimizing environmental impact. With a variety of vehicles, including cars and vans, Sharing Rides provides a convenient option for those looking to save money and reduce their carbon footprint. The service ensures safety, comfort, and timely rides, making it perfect for both solo travelers and groups heading in the same direction. Sharing Rides promotes community interaction and sustainability, providing a smarter and more responsible way to travel.",
    label: "Eco",
    bgColor: "#e8f5e9",
    labelColor: "#81c784",
    image: img6,
  },
  {
    title: "Bike Rides",
    description:
      "Bike Rides offer an eco-friendly and quick solution for urban commuters, allowing passengers to navigate through traffic with ease. Ideal for solo travelers or small groups, this service provides a range of bikes, including scooters and motorbikes, perfect for zipping through city streets. Whether for daily commutes or quick errands, Bike Rides ensures a convenient and cost-effective travel option. Safety is a priority, with helmets provided and strict adherence to traffic regulations. This service is perfect for those who value time, efficiency, and a reduced carbon footprint.",
    label: "Quick & Eco-friendly",
    bgColor: "#e0f7fa",
    labelColor: "#00acc1",
    image: img7,
  },
  {
    title: "Auto Rides",
    description:
      "Auto Rides provide a versatile and economical mode of transportation for city dwellers. Suitable for short to medium-distance travel, this service is ideal for solo passengers or small groups. With the ability to maneuver through traffic and access narrow streets, auto-rickshaws are a popular choice for urban transportation. Auto Rides emphasize affordability and accessibility, offering a practical solution for daily commutes, shopping trips, or visiting friends. Passengers can enjoy a breeze-filled journey while contributing to a lower environmental impact compared to traditional cars.",
    label: "Economical & Convenient",
    bgColor: "#f1f8e9",
    labelColor: "#8bc34a",
    image: img8,
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box sx={{ pb: 4, width: "100%" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
      >
        Our Services
      </Typography>
      <Grid
        container
        justifyContent="center"
        width={"94%"}
        margin={"auto"}
        spacing={3}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: theme.palette.neutral[200],
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  objectFit: "cover",
                  // height:380
                  // Ensure image covers the width
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  fontWeight={"900"}
                  sx={{ mt: 1, mb: 2, color: theme.palette.text.primary }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mb: 2, color: theme.palette.text.secondary }}
                >
                  {service.description.length > 200
                    ? `${service.description.slice(0, 200)}...`
                    : service.description}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    fontSize: "0.875rem",
                    border: "1px solid #4A00E0",
                    "&:hover": {
                      color: "#FFFFFF", // Text color on hover
                      backgroundColor: theme.palette.primary.main, // Use theme primary color on hover
                    },
                  }}
                >
                  Learn more →
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
