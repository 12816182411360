import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4A00E0", // Your primary color
    },
    neutral: {
      100: "#F5F5F5", // Neutral 100 color
      200: "#F1F0F0", // Neutral 200 color
    },
    background: {
      default: "#F5F5F5", // You can also apply Neutral 100 to the background if desired
    },
    // text: {
    //   primary: "##000000", // Your text color
    // },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif", // Your font family
  },
  text: {
    primary: "#000000", // Default primary text color
    secondary: "#494949", // You can define a secondary text color as well
  },
});

export default theme;
