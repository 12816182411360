import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import StarRateIcon from "@mui/icons-material/StarRate";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GavelIcon from "@mui/icons-material/Gavel";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CommuteIcon from "@mui/icons-material/Commute";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import { useTheme } from "@mui/material";

const services = [
  {
    title: "Driver App Login",
    description:
      "Drivers need secure access to the app to view ride requests and manage their schedules.",
    icon: <DriveEtaIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Real-Time Tracking",
    description:
      "Allows users to track the location of their booked vehicle in real-time.",
    icon: <GpsFixedIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Real-Time Car Details Fetching",
    description:
      "Users can view real-time details of the vehicle assigned to their ride.",
    icon: <CarRepairIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Review Features",
    description: "Users can rate and review drivers after completing a ride.",
    icon: <StarRateIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Referral Code",
    description:
      "Users can refer the app to others using a unique referral code, earning rewards.",
    icon: <GroupAddIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Penalty on Drink and Drive",
    description:
      "Penalize drivers caught under the influence of alcohol during duty.",
    icon: <GavelIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "GST 12% on Each Ride",
    description:
      "Automatically calculate and apply GST on every completed ride fare.",
    icon: <LocalAtmIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Sharing and Personal Booking",
    description:
      "Users can book rides for themselves or share rides with others.",
    icon: <DirectionsCarIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Vendor Can Add Multiple Vehicles",
    description:
      "Vendors (fleet owners) can add multiple vehicles to the platform.",
    icon: <AddCircleOutlineIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Commission for Vehicle Additions",
    description: "Reward the vendor who adds a vehicle when it gets a ride.",
    icon: <CommuteIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Tree-Like Structure",
    description:
      "Organize data in a hierarchical tree structure for efficient management.",
    icon: <AccountTreeIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: "Night Charges (5% After 12 AM)",
    description: "Apply surcharges for rides booked after midnight.",
    icon: <NightlifeIcon sx={{ fontSize: 40 }} />,
  },
];

const AboutUs = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: "relative",
        background: "linear-gradient(to right, #4a00e0, #8e2de2)", // Gradient background
        color: "#fff",
        p: 5,
        textAlign: "center",
        // minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width:"100%",
        // border:"5px solid red"
      }}
    >
      <Box sx={{ width:"94%" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold", mb: 3, lineHeight: 1.2 }}
          fontSize={{ xs: "1.4rem", md: "2.4rem" }}
        >
          About Taxi-Rider
        </Typography>
        <Typography variant="h6" width={{ xs: "100%", md: "70%" }} margin={"auto"} sx={{ mb: 4 }} fontSize={{ xs: "1rem", md: "1.4rem" }}>
          Mozo Rides is dedicated to providing the best ride experience across India. Whether you're
          looking for luxury, convenience, or a quick trip, we've got you covered! Our services span
          from city rides to long-distance travel, ensuring safety, comfort, and satisfaction every
          step of the way.
        </Typography>
        {/* <Divider sx={{ mb: 4, bgcolor: "#fff" }} /> */}
        <Typography variant="h5" sx={{ mb: 3 }}>
          Our Key Features:
        </Typography>
        <Grid container spacing={6} mb={8} >
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} >
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "#fff",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Box sx={{ mb: 1 }}>
                  {service.icon}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {service.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        {/* <Divider sx={{ mt: 4, mb: 4, bgcolor: "#fff" }} /> */}
        <Button
          variant="contained"
          sx={{
            backgroundColor:theme.palette.primary.main,
            color: "#fff",
            fontWeight: "bold",
            padding: "12px 24px",
            fontSize: "1rem",
            "&:hover": {
              backgroundColor: "#ffffff",
              color: theme.palette.primary.main,
            },
          }}
        >
          Learn More About Us
        </Button>
      </Box>
    </Box>
  );
};

export default AboutUs;
