import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import img4 from "../components/Images/distributorServices/4.png";
import img2 from "../components/Images/distributorServices/2.png";
import img3 from "../components/Images/distributorServices/3.png";
import img1 from "../components/Images/distributorServices/1.png";
import img5 from "../components/Images/distributorServices/5.png";
import img6 from "../components/Images/distributorServices/6.png"; // Note the different import name
import { useTheme } from "@mui/material";

const services = [
  {
    title: "Exclusive Territory",
    description:
      "The distributor is given an exclusive right to operate in their designated territory.",
    label: "Long Distance",
    bgColor: "#f3e5f5",
    labelColor: "#ba68c8",
    image: img1,
  },
  {
    title: "Profit Share",
    description:
      "The distributor earns 30% profit from every approved cabs.",
    label: "Reliable",
    bgColor: "#fff3e0",
    labelColor: "#ffb74d",
    image: img2,
  },
  {
    title: "Corporate Rides",
    description:
      "Corporate Rides offers professional and reliable transportation services tailored for business needs. Designed to provide comfort and punctuality, the service features a fleet of premium vehicles, including sedans and executive cars, perfect for corporate travel. Whether it's airport transfers, business meetings, or corporate events, Corporate Rides ensures a smooth and stress-free experience with skilled chauffeurs and well-maintained vehicles. Prioritizing efficiency, the service accommodates tight schedules and provides a comfortable environment for work on the go. With a focus on professionalism and convenience, Corporate Rides is ideal for executives, teams, and business clients seeking reliable transportation solutions.",
    label: "Scenic",
    bgColor: "#e3f2fd",
    labelColor: "#42a5f5",
    image: img3,
  },
  {
    title: "Payment Guarantee",
    description:
      "The company ensures a minimum payment guarantee for the distributor, starting at INR 1,000 per month for the first three months.",
    label: "Premium",
    bgColor: "#fde8e8",
    labelColor: "#e57373",
    image: img4,
  },
  {
    title: "Support",
    description:
      "The company provides necessary technical support, guidance, and training to the distributor.",
    label: "Flexible",
    bgColor: "#e8f0fe",
    labelColor: "#64b5f6",
    image: img5,
  },
  {
    title: "Lead Generation and Conversion",
    description:
      "The distributor is only required to provide complete and genuine leads for cab registration, with the company taking responsibility for the final approval and registration process​.",
    label: "Eco",
    bgColor: "#e8f5e9",
    labelColor: "#81c784",
    image: img6,
  },
];

const DistributorServices = () => {
  const theme = useTheme();
  return (
    <Box sx={{ pb: 4, width: "100%" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}
      >
        Our Services
      </Typography>
      <Grid
        container
        justifyContent="center"
        width={"94%"}
        margin={"auto"}
        spacing={3}
      >
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: theme.palette.neutral[200],
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  objectFit: "cover",
                  // height:380
                  // Ensure image covers the width
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  fontWeight={"900"}
                  sx={{ mt: 1, mb: 2, color: theme.palette.text.primary }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mb: 2, color: theme.palette.text.secondary }}
                >
                  {service.description.length > 200
                    ? `${service.description.slice(0, 200)}...`
                    : service.description}
                </Typography>
                {/* <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    fontSize: "0.875rem",
                    border: "1px solid #4A00E0",
                    "&:hover": {
                      color: "#FFFFFF", // Text color on hover
                      backgroundColor: theme.palette.primary.main, // Use theme primary color on hover
                    },
                  }}
                >
                  Learn more →
                </Button> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DistributorServices;
