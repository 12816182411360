import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Hidden,
  Divider,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Logo from "../components/Images/Logo.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const Header = () => {
  const menuItems = [
    "Home",
    "Services",
    "About us",
    "Contact us",
    "Distributor",
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <>
      {/* Header Section */}
      <AppBar
        position="static"
        sx={{ backgroundColor: "#E5E5E5", padding: "10px 0" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Ensure all items are centered vertically
            width: "93%",
            margin: "auto",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap:{
              xs:5,md:0
            }
          }}
        >
          {/* Left side: Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="img"
              src={Logo}
              alt="Janta Rides Logo"
              sx={{ height: isSmallScreen ? 40 : 50, marginRight: 3 }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#000",
                fontSize: isSmallScreen ? "14px" : "24px",
              }}
            >
              Janta Rides
            </Typography>
          </Box>

          {/* Center: Menu Items */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: {
                xs:2,md:10
              },
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            {menuItems.map((item) => (
              <Button
                key={item}
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: isTabletScreen ? "15px" : "20px",
                }}
              >
                {item}
              </Button>
            ))}
          </Box>

          {/* Right side: Social Icons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "start", // Center the "Follow" and icons vertically
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                marginRight: 2,
                color: "#000",
                fontWeight: "bold",
                fontSize: { sm: "1rem", md: "1.4rem" },
              }}
            >
              Follow
            </Typography>

            <Box sx={{ display: "flex", gap: 3 }}>
              <a href="https://www.facebook.com/profile.php?id=61564959732889">
                <IconButton color="inherit">
                  <FacebookIcon sx={{ color: "#000" }} />
                </IconButton>
              </a>
              <a
                href="https://www.instagram.com/ridewithjanta?igsh=MWxmN2lvcTQ0Mnk3dw%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="inherit">
                  <InstagramIcon sx={{ color: "#000" }} />
                </IconButton>
              </a>
              <IconButton color="inherit">
                <LinkedInIcon sx={{ color: "#000" }} />
              </IconButton>
              <IconButton color="inherit">
                <WhatsAppIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Footer Section */}
      <Box
sx={{
  backgroundColor: "#E5E5E5",
  textAlign: "center",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "24px",
  borderTop: "1px solid #000000",
  gap: 2,
  flexDirection: {
    xs: "column",
    md: "row",
  },
}}

      >
        <Typography
          variant="body2"
          sx={{
            color: "#000",
            marginRight: 2,
            fontSize: isSmallScreen ? 15 : 18,
          }}
        >
          Terms & Conditions
        </Typography>
        <Typography variant="body2">
          <FiberManualRecordIcon fontSize="0.6rem" sx={{ color: "#727272" }} />
        </Typography>

        <Typography
          variant="body2"
          sx={{ color: "#000", fontSize: isSmallScreen ? 16 : 20 }}
        >
          Privacy Policy
        </Typography>
        <Typography variant="body2">
          <FiberManualRecordIcon fontSize="0.6rem" sx={{ color: "#727272" }} />
        </Typography>
        <Link
          href="https://arvmultimedia.com/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textDecoration: "none",
            color: "#000",
            fontSize: isSmallScreen ? 15 : 18,
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Developed By : ARV Multimedia.com
        </Link>
      </Box>
    </>
  );
};

export default Header;
