import React from "react";
import HeroSection from "./HeroSection";

const Home = () => {
 return (
  <>
   <HeroSection />
  </>
 );
};

export default Home;

 