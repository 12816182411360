import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material";

const ContactUs = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: "#1D1D1D", color: "#fff", py: 8 }}>
      <Grid container spacing={2} justifyContent="center">
        {/* Contact Information Section */}
        <Grid item xs={12} md={5} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={12}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#1D1D1D",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    OUR MAIN OFFICE
                  </Typography>
                  <Typography variant="body2">
                    LKV 426 Left Side Lalkuan MB Road
                    <br />
                    New Delhi 110044
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#1D1D1D",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    PHONE NUMBER
                  </Typography>
                  <Typography variant="body2">
                    +91-7303046271
                    {/* <br />
          888-0123-4567 (Toll Free) */}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#1D1D1D",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    FAX
                  </Typography>
                  <Typography variant="body2">1-234-567-8900</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  color: "#1D1D1D",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    EMAIL
                  </Typography>
                  <Typography variant="body2">info@jantarides.com</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Contact Form Section */}
        <Grid item xs={12} md={7} lg={5}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              padding: "30px",
              borderRadius: "8px",
              textAlign: "left",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#ffffff", mb: 3 }}
            >
              Contact Us
            </Typography>

            <TextField
              fullWidth
              label="Enter your Name"
              variant="outlined"
              sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "4px" }}
            />
            <TextField
              fullWidth
              label="Enter a valid email address"
              variant="outlined"
              sx={{ mb: 2, backgroundColor: "#fff", borderRadius: "4px" }}
            />
            <TextField
              fullWidth
              label="Your Message"
              variant="outlined"
              multiline
              rows={4}
              sx={{ mb: 3, backgroundColor: "#fff", borderRadius: "4px" }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#ffffff",
                color: theme.palette.primary.main,
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
